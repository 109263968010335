body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}






.container {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  max-width: 720px;
  margin: 0 auto;
  padding: 10px 20px 1px 20px;
  border-radius: 5px;
  background-color: #fff;
  height: auto; 
  width: 120vw;
 box-sizing: border-box;

 
}
.content {
  max-width: 720px;
  
  margin: 0 auto;
  padding: 10px 20px 1px 20px;
  border: 1px solid #000000;
  border-radius: 5px;
  background-color: #fff;
  margin-left: 10px;
  margin-right: 10px;
  min-height: 800px; /* Set the height to match A4 paper height in pixels */
  height: auto;
}

#background {
  height: 100%;
  position: absolute;
  display: block; 
  width: 50%;
  opacity: 0.7;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: start;
  background-color: transparent;
}
#bg-text {
  color: lightgrey;
  font-size: 27px;
  transform: rotate(280deg);
  -webkit-transform: rotate(310deg);
  opacity: 0.7;
  z-index: -1;
  filter: alpha(opacity=40);
  background-color: transparent;
}

.invoice-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
 
}



.d-flex{
  display: flex;
  justify-content: start;
  align-items: center;
}


.d-flex2{
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.logo img {
  height: 70px;
  width: 70px;
 
  margin-right: 15px;
}
.comAddress {
 font-size: 12px;
 width: 70%;
}


.qr .img {
  margin-right: 100px;
  position: relative;
  width: 75px;
  height: 75px;
 
}

.s1-part1{
  width: 50%;
  margin-right:15px ;
}

.s1-part2{
  width: 50%;
  margin-left:15px ;
}

.mb-1{
  padding-bottom: 5px;
}

.right,.left {
  border-bottom: 2px solid black;
  font-size: 12px;
  border-top: 2px solid black;
}



.alignleft,.alignright {
  border-bottom: 2px solid black;
  font-size: 12px;
  margin-top: 3%;
}


.line-text{
  font-size: 12px;
}

td {
  text-align: center;
  font-size: 0.5rem;
}

table {
  border-collapse: collapse;
  width: 100%;
}

table,
th {
  border: 1px solid black;
}

th:not(:last-child),
td:not(:last-child) {
  border-right: 1px solid black;
}

th {
  font-size: 0.6rem;
  text-align: center;
}

.border-bottom {
  border-bottom: 1px solid black;
}

.text-end {
  text-align: end;
}


table:nth-of-type(1) thead th:nth-child(2),
table:nth-of-type(2) thead th:nth-child(2) {
  width: 40%; /* Adjust the width as needed */
}

table:nth-of-type(1) thead th:nth-child(4),
table:nth-of-type(2) thead th:nth-child(4) {
  width: 10%; /* Adjust the width as needed */
}

.cus_tr {
  border-top: 1px solid black;
}

.s5-part1{
  font-size: 14px;
}

.disfont{
  font-size: 10px;
}

.bottomright{
  text-align: end;
  font-size: 12px;
}

.bottomleft{
  font-size: 12px;
}

.center{
  text-align: center;
  font-size: 12px;
}

@media print {
  @page {
    margin: 0;
  }

  .body {
    margin: 0;
  }

  .original {
    page-break-after: always;
    
  }

  .duplicate {
    page-break-before: always;
   
  }

  .original, .duplicate {
    page-break-inside: avoid;
    padding: 20px;
    box-sizing: border-box;
  }

 
}



.style {
  text-align: center; 
  font-weight: bold; 
  font-size: 16px;
  
}


.copy{
  text-align: end; 
  margin-right: 14px; 
  margin-bottom: 0; 
  font-size: 8px;
}


.table2{
  font-size: 10px;
   border: 1px solid black;
}

.al-center{
  text-align: center;
}

.disclaimer{
  text-decoration-line: underline;
}

.for{
  text-decoration-line: underline; 
  text-align: right; 
  margin-top: -5%;
}


.table01{
  font-size: 10px;
   border: 1px solid black;
}

.border-less{
  border: none;
}


@media (max-width: 799px) {
  #background{
    width: 85%;
    margin-top: -20%;
  }
}

@media (max-width: 699px) {


  .container{
    width: 160%;
    margin: 0;
    
  }

  .content{
    width: 90%;
  }
  .style{
    font-size: 10px;
  }


  #background{
    width: 150%;
    margin-top: -10%;
  }

  .comAddress {
    font-size: 8px;
    width: 70%;
   }

   .logo img {
    height: 40px;
    width: 40px;
    margin-right: 15px;
  }
   

  .qr{
    width: 55px;
    height: 55px;
    margin-top: -10px;
    
  }


  .alignleft,.alignright,.line-text,.disfont{
    font-size: 8px;
  }

  .left,.right,.s5-part1{
    font-size: 10px;
  }
 


 
}


